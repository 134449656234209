<template>
    <div class="">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2>Welcome! Please Login to continue.</h2>
                </div>
                <div class="col-md-12">
                    <div class="col-md-6">
                        <div class="login-btn">
                            <button class="btn social-login fb" @click="loginFacebook">Login with facebook</button>
                            <button class="btn social-login glog" @click="googleLogin">Sign in with Google</button>
                            <button class="btn guest-btn social-login" @click="goToPayment">Continue as Guest</button>
                        </div>
                    </div>

                    <div class="col-md-6 flex-part">
                        <div class="or-border">
                            <span>or</span>
                        </div>
                        <div class="checkout_form">
                            <ValidationObserver>
                                <form @submit.prevent="customerLogin">
                                    <div class="form-group required">
                                        <ValidationProvider name="Email" rules="email|required" v-slot="{ errors }">
                                            <input class="form-control" v-model="customer.email" name="email"
                                                placeholder="Email" type="email" />
                                            <span style="color: red">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group required">
                                        <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                                            <input type="password" v-model="customer.password" name="password"
                                                placeholder="Password" class="form-control" />
                                            <span style="color: red">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                        <span style="color: red" v-if="resError">{{ resError }}</span>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn login-btn">Login</button>
                                    </div>


                                </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid full-login-wrap">
            <div class="row">
                <div class="col-md-12">
                    <div class="col-md-6">
                        <router-link class="btn sign-up" :to="{ name: 'registration' }">
                            Sign up
                        </router-link>
                    </div>
                    <div class="col-md-6">
                        <button class="btn forget-btn">Forgot Password</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex'
export default {
    name: "login",
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            customer: {
                email: "",
                password: "",
            },
            showError: false,
            resError: ''
        };
    },
    computed: {
        ...mapGetters(["shoppingBag"]),
    },
    watch: {
        'customer.email'(oldvalue, newVal) {
            if (newVal) {
                this.resError = ''
            }
        },
        'customer.password'(oldvalue, newVal) {
            if (newVal) {
                this.resError = ''
            }
        },

    },
    methods: {
        customerLogin(e) {
            // console.log("login")
            e.preventDefault();
            this.$store.dispatch("login", this.customer).then((res) => {
                if (res === undefined) {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        title: 'Successfully Logedin.',
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });
                    this.$router.push('/customer-dashboard').catch(() => { });
                    this.customer = {
                        email: "",
                        password: ""
                    };
                } else {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        title: res.message,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            });
        },
        goToPayment() {
            if (this.shoppingBag.length > 0) {
                this.$router.push('/checkout').catch(() => { })
            }
        },
        loginFacebook() {
            window.location.href = 'https://eurasiasupplies.com/eurasia_backedapi/login/facebook'
        },
        googleLogin() {
            window.location.href = 'https://eurasiasupplies.com/eurasia_backedapi/auth/google'
        }
    },
}
</script>
<style scoped>
.login-btn .social-login {
    width: 100%;
    display: block;
    color: #fff;
    padding: 8px 0px;
    margin-bottom: 20px;
    border-radius: 4px;
    font-size: 17px;
    text-transform: uppercase;
}

.guest-btn {
    background: #4CAF50;
}

.guest-btn:hover {
    background: #09a005;
}

.fb {
    background: #1877f2;
}

.glog {
    background: red;
}

.form-group {
    text-align: left;
}

button.btn.login-btn {
    width: 97%;
    background: #4CAF50;
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
    border-radius: 3px;
}

button.btn.login-btn:hover {
    background: #09a005;
}

.container-fluid.full-login-wrap {
    background: gray;
    margin: 15px 0px;
}

a.btn.sign-up {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
    border-radius: 3px;
    color: #fff;
}

.btn.forget-btn {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
    border-radius: 3px;
    color: #fff;
}

.col-md-6.flex-part {
    display: flex;
    justify-content: space-between;
}

.checkout_form {
    width: 95%;
}

.or-border {
    border-right: 5px solid #ccc;
    position: relative;

}

.or-border span {
    position: ABSOLUTE;
    top: 38%;
    background: #ddd;
    height: 30px;
    width: 30px;
    left: -12px;
    border-radius: 27px;
    padding: 3px;
    color: #1e1c1c;
    border: 1px solid #ccc;
    font-size: 15px;
}
</style>